import React, { useEffect, useState } from 'react'
import { Button, Col, Input, Label, Modal, ModalBody, Row, Spinner } from 'reactstrap'

function ModifyLineItem({ openModifyLine, setOpenModifyLine, loader, setModifyLineData }) {
    const [bookingText, setBookingText] = useState('')
    const [jobText, setJobText] = useState(null)

    useEffect(() => {
        if (openModifyLine?.open) {
            setBookingText(openModifyLine?.data?.invoice_text)
            setJobText(openModifyLine?.data?.item_text)
        }
    }, [openModifyLine])

    const onSubmit = () => {
        setModifyLineData({ invoice_text: bookingText, item_text: jobText })
        setOpenModifyLine({ open: false, id: openModifyLine?.id, data: null, customLineItem: false })
    }

    return (
        <Modal isOpen={openModifyLine?.open} className="modal-dialog-centered modal-lg" toggle={() => setOpenModifyLine({ open: false, id: openModifyLine?.id, data: null, customLineItem: false })} >
            <ModalBody className="px-10">
                <div>
                    <Row className='mb-10'>
                        <Col md='12' className='d-flex align-items-center justify-content-center'>
                            <h3 className='mb-0' style={{ color: '#6C757D' }}>Modify Line Item</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Label className='d-flex align-items-center me-3'>Booking</Label>
                        <Col md='12'>
                            <Input
                                type='text'
                                placeholder='Enter booking text'
                                value={bookingText}
                                onChange={(e) => setBookingText(e.target.value)}
                            />
                        </Col>
                    </Row>
                    {openModifyLine?.customLineItem ? '' :
                        <Row className='my-5'>
                            <Label className='d-flex align-items-center me-3'>Jobs</Label>
                            <Col md='12'>
                                <Input
                                    type='textarea'
                                    placeholder='Enter job text'
                                    rows='5'
                                    value={jobText ? typeof(jobText) == 'string' ? jobText : jobText.join('\n') : ''}
                                    onChange={(e) => setJobText(e.target.value)}
                                />
                            </Col>
                        </Row>
                     }
                    <Row className='mt-10'>
                        <div className='d-flex justify-content-end'>
                            <Button type="button" className='btn btn-sm' color="secondary" onClick={() => setOpenModifyLine({ open: false, id: openModifyLine?.id, data: null })} style={{ marginRight: "10px" }}>
                                Cancel
                            </Button>
                            <Button type='submit' disabled={loader === 'modify_line'} color='primary' className='btn btn-sm' onClick={onSubmit}>
                                {loader === 'modify_line' ? <Spinner size='sm' /> : 'Save'}
                            </Button>
                        </div>
                    </Row>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ModifyLineItem