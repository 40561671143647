import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Input, Label, Row, Spinner } from 'reactstrap';
import { SettingsService } from '../../../modules/Services/Settings';
import toast from 'react-hot-toast';

function BankDetailSetting() {
    const [loader, setLoader] = useState(null)
    const entity_id = localStorage.getItem('entity_id')
    const [bankData, setBankData] = useState(null)

    const getBankDetail = () => {
        SettingsService.getBankDetail(entity_id)
        .then((res: any) => {
            setBankData(res?.data?.response?.bank_detail ? JSON.parse(res?.data?.response?.bank_detail) : null)
        })
        .catch((err: any) => console.log(err?.response?.data?.message))
    }

    useEffect(() => {
        getBankDetail()
    }, [])

    const setBankDetail = () => {
        setLoader('update')
        const data = {
            bank_name: bankData?.bank_name,
            address: bankData?.address,
            iban: bankData?.iban,
            swift: bankData?.swift,
            include_invoice: bankData?.include_invoice
        }
        SettingsService.updateBankDetail(entity_id, { bank_detail: data })
        .then((res: any) => {
            toast.success(res?.data?.message)
        })
        .catch((err: any) => toast.error(err?.response?.data?.message))
        .finally(() => setLoader(null))
    }

    return (
        <Card>
            <CardHeader>
                    <CardTitle className='dropdown-header'>Bank Details</CardTitle>
            </CardHeader>
            <CardBody>
                <Row className='d-flex align-items-center'>
                    <Col md='3'>
                        <Label className='m-0'>Bank Name</Label>
                    </Col>
                    <Col md='9'>
                        <Input type='text' 
                            placeholder='Enter Bank name'
                            value={bankData?.bank_name} 
                            onChange={(e) => setBankData((pre: any) => ({...pre, bank_name: e.target.value}))} 
                        />
                    </Col>
                </Row>
                <Row className='d-flex align-items-center my-5'>
                    <Col md='3'>
                        <Label className='m-0'>Address</Label>
                    </Col>
                    <Col md='9'>
                        <Input type='textarea' 
                            placeholder='Enter Address'
                            value={bankData?.address} 
                            onChange={(e) => setBankData((pre: any) => ({...pre, address: e.target.value}))} 
                        />
                    </Col>
                </Row>
                <Row className='d-flex align-items-center'>
                    <Col md='3'>
                        <Label className='m-0'>IBAN</Label>
                    </Col>
                    <Col md='9'>
                        <Input type='text' 
                            placeholder='Enter IBAN'
                            value={bankData?.iban} 
                            onChange={(e) => setBankData((pre: any) => ({...pre, iban: e.target.value}))}
                        />
                    </Col>
                </Row>
                <Row className='d-flex align-items-center my-5'>
                    <Col md='3'>
                        <Label className='m-0'>Swift</Label>
                    </Col>
                    <Col md='9'>
                        <Input type='text' 
                            placeholder='Enter Swift'
                            value={bankData?.swift} 
                            onChange={(e) => setBankData((pre: any) => ({...pre, swift: e.target.value}))} 
                        />
                    </Col>
                </Row>
                <Row className='d-flex align-items-center'>
                    <Col>
                        <Input type='checkbox' className='me-3' checked={bankData?.include_invoice} onChange={(e) => setBankData((pre: any) => ({...pre, include_invoice: e.target.checked}))} />
                        <Label className='m-0'>Include On Invoice</Label>
                    </Col>
                </Row>
                <Row className='mt-10'>
                        <div className='d-flex justify-content-end'>
                            <Button type="button" color="primary" className='mx-2 btn btn-sm' disabled={loader === 'update'} onClick={() => setBankDetail()}>
                                { loader ? <Spinner size='sm'/> : <span className='align-middle d-sm-inline-block d-none'>Save</span>}
                            </Button>
                        </div>
                    </Row>
            </CardBody>
        </Card>
    )
}

export default BankDetailSetting