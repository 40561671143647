import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Button, Col, Form, Input, Label, Modal, ModalBody, Row, Spinner } from 'reactstrap'
import ReactSelect from 'react-select'
import Flatpickr from 'react-flatpickr';
import { customSelectStyles, selectThemeColors } from '../../modules/commonModel/Utils';
import { InvoiceService } from '../../modules/Services/Invoice';
import toast from 'react-hot-toast';
import moment from 'moment';
import UILoader from '../../modules/commonModel/Ui-loader';
import ComponentSpinner from '../../modules/apps/user-management/users-list/components/loading/Loading-spinner';

function InvoicePayment({ paymentModal, setPaymentModal, invoiceId, render, setRender, currentCustomer, setRenderElementService }) {
    const { control, handleSubmit, reset, formState: { errors } } = useForm({
                                                                                defaultValues: {
                                                                                    payment_date: new Date(),
                                                                                    payment_method: null,
                                                                                    payment_amount: '',
                                                                                    payment_note: ''
                                                                                }
                                                                            });
    const entity_id = localStorage.getItem('entity_id')
    const [loader, setLoader] = useState(null)
    const [methodOption, setMethodOption] = useState(null)

    function getPaymentSatus() {
        InvoiceService.getPaymentStatusList(entity_id)
        .then((res: any) => {
            const data = res.data?.map((item: any) => {
                return { value: item?.id, label: item?.name }
            })
            setMethodOption(data)
        })
        .catch((err: any) => {
            toast.error(err?.response?.data?.message)
        })
    }

    const onSubmit = (data: any) => {
        setLoader('create')
        const payload = {
            entity_id: entity_id,
            invoice_id: invoiceId,
            customer_id: currentCustomer?.value,
            date: data?.payment_date ? typeof data?.payment_date == 'object' ? moment(data?.payment_date[0]).format('YYYY-MM-DD') : data?.payment_date : null,
            method_id: data?.payment_method?.value,
            amount: data?.payment_amount,
            notes: data?.payment_note
        }

        InvoiceService[paymentModal?.payment_id ? 'updatePayment' : 'createPayment'](entity_id, payload, paymentModal?.payment_id)
        .then((res: any) => {
            toast.success(res?.data?.message)
            setPaymentModal({ isOpen: false, payment_id: null })
            setRender(!render)
            setRenderElementService(1)
        })
        .catch((err: any) => {
            toast.error(err?.response?.data?.message)
        })
        .finally(() => setLoader(null))
    }

    const editPaymentDetail = () => {
        setLoader('edit')
        InvoiceService.editPayment(entity_id, paymentModal?.payment_id)
        .then((res: any) => {
            const obj = {
                payment_date: res?.data?.date,
                payment_method: { value: res?.data?.method_id, label: res?.data?.method_name },
                payment_amount: res?.data?.amount,
                payment_note: res?.data?.notes
            }
            reset(obj)
        })
        .catch((err: any) => {
            toast.error(err?.response?.data?.message)
        })
        .finally(() => setLoader(null))
    }

    useEffect(() => {
        if (paymentModal?.isOpen) {
            getPaymentSatus()
            if (paymentModal?.payment_id) editPaymentDetail()
        } else reset(
            {
                payment_date: new Date(),
                payment_method: null,
                payment_amount: '',
                payment_note: ''
            }
        )
    }, [paymentModal])

    return (
        <Modal isOpen={paymentModal?.isOpen} className="modal-dialog-centered modal-lg" toggle={() => setPaymentModal({ isOpen: false, payment_id: null })} >
            <ModalBody className="px-sm-5">
                <UILoader blocking={loader === 'edit'} loader={<ComponentSpinner />} overlayColor='rgba(245, 245, 240, .1)' className={loader === 'edit' ? 'loader-blur' : ''}>
                    <Row className='my-5'>
                        <h3 className='d-flex justify-content-center'>{paymentModal?.payment_id ? 'Edit' : 'Add' } Payment</h3>
                    </Row>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className='p-5 pb-0'>
                            <Col md='4' className='d-flex'>
                                <Label className='d-flex align-items-center m-0 me-6'>Date</Label>
                                <Controller name='payment_date' control={control} rules={{ required: true }}
                                    render= {({ field }) => (
                                        <Flatpickr
                                            {...field}
                                            className='form-control'
                                            placeholder='Select Date'
                                            options={{
                                                enableTime: false,
                                                altInput: true,
                                                dateFormat: 'Y-m-d H:i',
                                                disableMobile: true
                                            }}
                                        />
                                    )}
                                />
                            </Col>
                            <Col md='4' className='d-flex'>
                                <Label className='d-flex align-items-center m-0 me-3'>Method</Label>
                                <Controller name='payment_method' control={control} rules={{ required: true }}
                                    render= {({ field }) => (
                                        <ReactSelect
                                            {...field}
                                            isClearable={false}
                                            className='react-select w-100'
                                            classNamePrefix='select'
                                            placeholder='Select Status'
                                            name='callback-react-select'
                                            styles={customSelectStyles(errors.payment_method)}
                                            theme={selectThemeColors}
                                            options={methodOption}
                                            onChange={(data) => {
                                                field.onChange(data)
                                            }}
                                        />
                                    )}
                                />
                            </Col>
                            <Col md='4' className='d-flex'>
                                <Label className='d-flex align-items-center m-0 me-3'>Amount</Label>
                                <Controller name='payment_amount' control={control} rules={{ required: true }}
                                    render= {({ field }) => (
                                        <Input {...field} type='number' placeholder='Enter Amount' 
                                            style={{border: errors.payment_amount && '1px solid #ea5455'}} 
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row className='ps-5'>
                            <Col md='4'>
                                {errors?.payment_date && (<small className="text-danger ms-16">Date is a required field</small>)}
                            </Col>
                            <Col md='4'>
                                {errors?.payment_method && (<small className="text-danger ms-16">Method is a required field</small>)}
                            </Col>
                            <Col md='4'>
                                {errors?.payment_amount && (<small className="text-danger ms-16">Amount is a required field</small>)}
                            </Col>
                        </Row>
                        <Row className='p-5'>
                            <Col md={12} className='d-flex'>
                                <Label className='form-label me-3' for='notes'>Notes</Label>
                                <Controller name='payment_note' control={control} rules={{ required: false }}
                                    render= {({ field }) => (
                                        <Input type='textarea' rows='2' placeholder='Enter Notes' {...field} />
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row className='p-5'>
                            <div className='d-flex justify-content-end'>
                                <div>
                                    <Button type="button" size='sm' color="secondary" className='me-2' onClick={() => setPaymentModal({ isOpen: false, payment_id: null })}>
                                        Cancel
                                    </Button>
                                    <Button type='submit' size='sm' disabled={loader === 'create'} color='primary'>
                                        {loader === 'create' ? <Spinner size='sm' /> : <span className='align-middle d-sm-inline-block d-none'>{paymentModal?.payment_id ? 'Update' : 'Add'}</span>}
                                    </Button>
                                </div>
                            </div>
                        </Row>
                    </Form>
                </UILoader>
            </ModalBody>
        </Modal>
    )
}

export default InvoicePayment