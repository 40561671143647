import React from 'react'
import { Col, Row } from 'reactstrap'
import BankDetailSetting from './BankDetailSetting'
import StripeConfiguration from './StripeConfiguration'
import TaxConfiguration from './TaxConfiguration'

function FinancialConfig({ entityDetail }) {
    return (
        <Row>
            <Col md={4}>
                <BankDetailSetting />
            </Col>
            <Col md={4}>
                <StripeConfiguration />
            </Col>
            <Col md={4}>
                <TaxConfiguration entityDetail={entityDetail} />
            </Col>
        </Row>
    )
}

export default FinancialConfig